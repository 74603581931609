import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../App.css";
import Modal from "react-bootstrap/Modal";
import ReactPlayer from "react-player";

const LeftSideBar = () => {
  const [vidUrl, setVidUrl] = useState("");
  const [modalShow, setModalShow] = React.useState(false);

  const videoModal = (type) => {
    if (type === "address") {
      setVidUrl("https://diro.wistia.com/medias/adbe2gfuvb");
    } else {
      setVidUrl("https://diro.wistia.com/medias/8rt2zysyqp");
    }
    setModalShow(true);
  };

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {vidUrl === "https://diro.wistia.com/medias/adbe2gfuvb" && (
              <h5>Address verification tutorial</h5>
            )}
            {vidUrl === "https://diro.wistia.com/medias/8rt2zysyqp" && (
              <h5>Bank verification tutorial</h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <video src={AddressVideo}></video> */}
          <ReactPlayer
            controls={true}
            width={"100%"}
            height={"100%"}
            url={vidUrl}
          />
        </Modal.Body>
        {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
      </Modal>
    );
  }

  return (
    <>
      <Row
        className="h-adj p-lg-0 m-0 mr-lg-2"
        style={{ height: "85vh", maxWidth: "600px" }}
      >
        <Col className="p-lg-0" style={{marginLeft:"-10px"}}>
          <div>
            <p className="fs-5" style={{ fontWeight: "500",fontFamily:"bwekBoldFont",color:"#000000" }}>
              Bank account and address verification in vendor onboarding
            </p>
            <div className="fs-6 " style={{ textAlign: "justify",fontFamily:"bwekFont", color:"#787878" }}>
              <p className="text-left">
                <span style={{ textAlign: "justify" }}>
                  To ensure  both timely and accurate payments to BW Epic Kosan
                  vendors, and to reduce the risk of fraud, we ask you to verify
                  bank account ownership and proof of address.{" "}
                </span>{" "}
              </p>
              <p className="text-left">
                <span style={{ textAlign: "justify" }}>
                  {" "}
                 BW Epic Kosan partners with {" "}
                  <a href="https://diro.io/" target="_blank" className="anchor">
                    DIRO
                  </a>
                  {"  "} for this{" "}
                </span>{" "}
                process, the leading provider of bank verification solutions trusted
                by F500 and Tier 1  global banks. Visit DIRO <span>
              <a
                href="https://trust.diro.io/"
                className="anchor"
                target="_blank"
              >
                 Trust Center.
              </a>
            </span>
              </p>
              {/* <p className="text-left">
                Watch our tutorial to understand the process for{" "} */}
                {/* <button
                // href="https://diro.io/products/utility-bill-verification/"
                // href="https://diro.wistia.com/medias/adbe2gfuvb"
                onClick={handleModal}
                // target="_blank"
                className="black-anchor"
              >
                {" "}
                address{" "}
              </button>{" "} */}
                {/* <span
                  className="anchor"
                  style={{
                    textDecoration: "underline",
                    textUnderlineOffset: "2px",
                    textDecorationThickness: "1px",
                    cursor: "pointer",
                  }}
                  onClick={() => videoModal("address")}
                >
                  address
                </span>
                <MyVerticallyCenteredModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />{" "}
                and{" "} */}
                {/* <a
                href="https://diro.wistia.com/medias/8rt2zysyqp"
                target="_blank"
                className="black-anchor"
              >
                bank
              </a>{" "} */}
                {/* <span
                  className="anchor"
                  style={{
                    textDecoration: "underline",
                    textUnderlineOffset: "2px",
                    textDecorationThickness: "1px",
                    cursor: "pointer",
                  }}
                  onClick={() => videoModal("bank")}
                >
                  bank
                </span>{" "}
                verifications.
              </p> */}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default LeftSideBar;
