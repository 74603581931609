import React, { useState, useEffect, useRef } from "react";
import IFrame from "react-iframe";
import { useContext } from "react";
import { Context } from "../context";
import { useNavigate, useParams } from "react-router-dom";
// import axios from "axios";
import { env as environment } from "../controllers/environment";
import NavBar from "./NavBar";
import Sidebar from "./Sidebar";
import { Helmet } from 'react-helmet';

const Verification = ({ iframeRef }) => {
  const [statusUpdated, setStatusUpdated] = useState(false);
  const [iframeUrl, setIframeUrl] = useState();
  const params = useParams();
  // const [iframeItem, setIframeItem] = useState();
  const navigate = useNavigate();
  const { buttonSelection } = useContext(Context);

  useEffect(() => {
    // if (!inputEmail) navigate("/buttons");
    // === Set iframe urls ===
    // console.log(params);
    if (!buttonSelection) navigate("/");
    if (buttonSelection === "bank_verify")
      setIframeUrl(environment.bankButton + params.trackId);
    if (buttonSelection === "bank_verify_full_doc")
      setIframeUrl(environment.bankButtonFullDoc + params.trackId);
    if (buttonSelection === "utility_verify")
      setIframeUrl(environment.utilityButton + params.trackId);

    // === Eventlistner for capture process ===
    window.addEventListener("message", iframeEventListner);
    // === Cleanup on unmount ===
    return () => {
      setIframeUrl();
      window.removeEventListener("message", iframeEventListner);
    };
  }, []); //eslint-disable-line

  // === EventListener function ===
  const iframeEventListner = async (event) => {
    // console.log("11111111", event?.data);
    // const sessionId = event?.data?.sessionid;
    // const buttonId = event?.data?.buttonid;

    // === Event when Exit button is clicked ===`
    sessionStorage.setItem("event",JSON.stringify(event.data));
    if (event.data === "Terminate session from guac") {
      navigate("/");
      sessionStorage.setItem("key","1");
      // window.location.reload();
    }
    // === Event when document is submitted successfully ===
    if (event?.data?.progress_status === "Submitted" && !statusUpdated) {
     
      if (event?.data?.category ==="address"){sessionStorage.setItem("submitStatus","address")}
      if (event?.data?.buttonid ===environment.staticBankOnlyButtonId){sessionStorage.setItem("submitStatus","bank")}
      if (event?.data?.buttonid ===environment.staticBankFullButtonId){sessionStorage.setItem("submitStatus","bankfull")}

      window.removeEventListener("message", iframeEventListner);
      setTimeout(() => {
        navigate("/");
      }, 4000);

      setStatusUpdated(true);
    }
  };

  return (
    <div className="iframe_wrapper">
      {/* {!fullscreen && <Header />} */}
      {/* <NavBar redirect={false} page={"verification"} iframe={IFrameRef} /> */}
      
      {/* <Helmet>
        <meta
          http-equiv="Content-Security-Policy"
          content={`frame-src 'self' ${environment.frameSource}`}
        />
      </Helmet> */}
     
      <Sidebar flScreen={true} />
      <div className="iframe" ref={iframeRef}>
        <IFrame
          url={iframeUrl}
          width="100%"
          height="100%"
          frameBorder="0"
          id=""
          className="iframe-element ms-lg-5 pl-sm-5 "
          display="block"
          position="relative"
          allow="clipboard-read *; clipboard-write *"
          // onLoad={handleIframe}
        />
      </div>
    </div>
  );
};

export default Verification;
