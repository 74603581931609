import React, { useEffect, useState, useRef } from "react";
import { useContext } from "react";
import { Context } from "../context";
import Support from "./Support";
import Modal from "react-bootstrap/Modal";
import ReactPlayer from "react-player";
// import PlusIcon from "../assets/plus.png";
// import MinusIcon from "../assets/minus.png";

const Sidebar = ({ flScreen, noicons }, props) => {
  const [vidUrl, setVidUrl] = useState("");
  const [modalShow, setModalShow] = React.useState(false);

  const videoModal = (type) => {
    if (type === "address") {
      setVidUrl("https://diro.wistia.com/medias/adbe2gfuvb");
    } else {
      setVidUrl("https://diro.wistia.com/medias/8rt2zysyqp");
    }
    setModalShow(true);
  };

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {vidUrl === "https://diro.wistia.com/medias/adbe2gfuvb" && (
              <h5>Address verification tutorial</h5>
            )}
            {vidUrl === "https://diro.wistia.com/medias/8rt2zysyqp" && (
              <h5>Bank verification tutorial</h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <video src={AddressVideo}></video> */}
          <ReactPlayer
            controls={true}
            width={"100%"}
            height={"100%"}
            url={vidUrl}
          />
        </Modal.Body>
        {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
      </Modal>
    );
  }

  //   console.log(noicons, "=====================flScreen========================");
  const accObj = {
    acc1: true,
    acc2: true,
    acc3: true,
    acc4: true,
  };
  const { supportPage, setSupportPage } = useContext(Context);
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const [accordion, setAccordion] = useState(accObj);
  const divRef = useRef(null);
  // const [fullscreen, setFullscreen] = useState(false);
  // const { fullscreen, setFullscreen } = useContext(Context);
  const handleToggleBody = (id) => {
    let accBoolean = accordion[id];
    setAccordion(accObj);
    setAccordion((prev) => {
      return { ...prev, [id]: !accBoolean };
    });
  };
  useEffect(() => {
    if (noicons) {
      // setFullscreen(false);
      // document.exitFullscreen();
      setSidebarToggle(true);
    } else if (flScreen) {
      // window.scrollTo(0, 0);
      // elem.requestFullscreen();
      setSidebarToggle(false);
      // setFullscreen(true);
    }
  }, []); //eslint-disable-line
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        // console.log("modalshow",modalShow)
        // Clicked outside the div, do something
        if (flScreen && modalShow) {
          setSidebarToggle(false);
        }
        // console.log("Clicked outside the div");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); //eslint-disable-line
  const handleSupport = () => {
    if (flScreen) {
      setSupportPage(true);
    }
  };
  return (
    <div
      ref={divRef}
      id="sidebar"
      className={`sidebar_wrap d-none d-lg-block ${
        sidebarToggle ? "" : "collapsed"
      }`}
    >
      {supportPage && <Support />}
      {!noicons && (
        <div className="nav-btn">
          {/* <button id="toggle2" style={{ cursor: "pointer" }} title="Toggle fullscreen" onClick={toggleFullScreen}>
					<i id="icon2" className={`bi ${fullscreen ? "bi-fullscreen-exit" : "bi-arrows-fullscreen"}`}></i>
				</button> */}

          <button
            id="toggle"
            onClick={() => setSidebarToggle(!sidebarToggle)}
            style={{ cursor: "pointer" }}
            title="Toggle sidebar"
          >
            <i
              id="icon1"
              className={`bi ${
                sidebarToggle ? "bi-arrow-bar-left" : "bi-list"
              }`}
            ></i>
          </button>
        </div>
      )}

      <div className="content">
        <div className="d-flex content_inner">
          <div
            className={`collumn-faq ${
              window.location.href.includes("/verification") &&
              "hide-sidebar-border"
            }`}
          >
            <div className="faq_quote">
              <div
                className="heading "
                style={{
                  marginLeft: "-20px",
                  marginRight: "10px",
                  marginTop: "-30px",
                }}	
              >
                <p className="fs-5" style={{ fontWeight: "500",fontFamily:"bwekBoldFont",color:"#000000"}}>
                  Bank account and address verification in vendor onboarding
                </p>
                <div className="fs-6 " style={{ textAlign: "justify",fontFamily:"bwekFont", color:"#787878" }}>
                  <div>
                    To ensure both timely and accurate payments to BW Epic Kosan
                    vendors, and to reduce the risk of fraud, we ask you to
                    verify bank account ownership and proof of address.
                  </div>
                  <div className="mt-3">
                    BW Epic Kosan partners with{" "}
                    <a
                      href="https://diro.io/"
                      target="_blank"
                      className="anchor"
                    >
                      DIRO
                    </a>{" "}
                    for this process, the leading provider of bank verification
                    solutions trusted by F500 and Tier 1 global banks.&nbsp;Visit DIRO <span className="pe-1 ">
              <a
                href="https://trust.diro.io/"
                className="anchor"
                target="_blank"
                style={{ backgroundColor: "006699 !important" }}
              >
                Trust Center.
              </a>
            </span>
                  </div>
                  {/* <div className="mt-3">
                    Watch our tutorial to understand the process for{" "}
                    {/* <a
                      href="https://diro.io/products/utility-bill-verification/"
                      target="_blank"
                      className="black-anchor"
                    >
                      address
                    </a>{" "} */}
					{/* <span className="anchor" style={{ textDecoration: "underline",textUnderlineOffset:"2px",textDecorationThickness:"1px",cursor:"pointer" }} onClick={() => videoModal("address")}>
						address
					</span> */}

					{/* <MyVerticallyCenteredModal
						show={modalShow}
						onHide={() => setModalShow(false)}
						
					/>
                    {" "}and {" "} */}
                    {/* <a
                      href="https://diro.io/products/bank-account-verification//"
                      target="_blank"
                      className="black-anchor"
                    >
                      bank
                    </a>{" "} */}
					{/* <span className="anchor" style={{ textDecoration: "underline",textUnderlineOffset:"2px",textDecorationThickness:"1px",cursor:"pointer" }} onClick={() => videoModal("bank")}>
						bank 
					</span>
                    {" "}verifications. */}
                  {/* </div>  */}
                </div>
              </div>
              {/* {flScreen && (
								<button style={{ marginLeft: "-1px" }} className="support_button" onClick={handleSupport}>
									Get support
								</button>
							)}
							<p className="sticky_para">
								<a href="https://diro.io/term-condition/" target="blank" rel="noopener noreferrer">
									Terms of Use.
								</a>{" "}
								<a href="https://diro.io/privacy-policy/" target="blank" rel="noopener noreferrer">
									Privacy Policy.
								</a>
							</p> */}
            </div>
          </div>
          {/* <div className="collumn-faq">
						<div className="faq overflow">
							<h2>Frequently asked questions</h2>
							<div className="accordion accordion-flush" id="accordionFlushExample">
								<div className="accordion-item">
									<h2 className="accordion-header" id="flush-headingOne">
										<button className={`accordion-button ${accordion.acc1 && "collapsed"}`} type="button" onClick={() => handleToggleBody("acc1")}>
											{accordion.acc1 ? (
												<img className="plus-minus" src={PlusIcon} alt="more" />
											) : (
												<img className="plus-minus" src={MinusIcon} alt="less" />
											)}
											What information will be shared?
										</button>
									</h2>
									<div id="flush-collapseOne" className={`accordion-collapse${accordion.acc1 ? " collapse-box" : ""}`}>
										<div className="accordion-body">
											<ul>
												<li>
													Your bank statement will not be shared with Citibank. Only selected fields as highlighted inside each session will be
													shared. All other data will be purged.
												</li>
												<li>
													Any sensitive information such as balance and transactional data from your bank statement will never be shared with
													Citibank.
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div className="accordion-item">
									<h2 className="accordion-header" id="flush-headingFour">
										<button className={`accordion-button ${accordion.acc2 && "collapsed"}`} type="button" onClick={() => handleToggleBody("acc2")}>
											{accordion.acc2 ? (
												<img className="plus-minus" src={PlusIcon} alt="more" />
											) : (
												<img className="plus-minus" src={MinusIcon} alt="less" />
											)}
											How does my password remain private?
										</button>
									</h2>
									<div id="flush-collapseFour" className={`accordion-collapse${accordion.acc2 ? " collapse-box" : ""}`}>
										<div className="accordion-body">
											<ul>
												<li>
													The entire verification session provides end-to-end encryption and remains private. In other words, you will not share your
													password or login credentials with Fraud.net, DIRO, or Citibank.
												</li>
												<li>Most banks use one time passwords (OTP) with multi-layer security.</li>
												<li>
													Most banks use two-factor authentication (2FA) or multi-factor authentication (MFA). The second factor is sometimes offline,
													or on a separate device, such as a smartphone for high security and to eliminate fraud.
												</li>
												<li>
													Since you will not do a transaction, only verify bank account ownership, your transaction password will never be asked for
													in the verification session.
												</li>
												<li>
													Visit DIRO to learn more about{" "}
													<a href="https://diro.io/user-privacy/" target="blank" rel="noopener">
														user privacy
													</a>
													.
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div className="accordion-item">
									<h2 className="accordion-header" id="flush-headingThree">
										<button className={`accordion-button ${accordion.acc3 && "collapsed"}`} type="button" onClick={() => handleToggleBody("acc3")}>
											{accordion.acc3 ? (
												<img className="plus-minus" src={PlusIcon} alt="more" />
											) : (
												<img className="plus-minus" src={MinusIcon} alt="less" />
											)}
											Why should I trust Fraud.net and DIRO?
										</button>
									</h2>
									<div id="flush-collapseThree" className={`accordion-collapse${accordion.acc3 ? " collapse-box" : ""}`}>
										<div className="accordion-body">
											<ul>
												<li>
													Fraud.net and DIRO are trusted by F500 and Tier 1 global banks in consumer and enterprise verifications across 195
													countries. Its solution is used to verify bank account ownership, proof of address, and other documents to eliminate fraud,
													email spoofing, and impersonation issues.
												</li>
												<li>
													Visit Fraud.net to learn more about{" "}
													<a href="https://fraud.net/know-your-vendor-demo-video/" target="blank" rel="noopener">
														know your vendor.
													</a>
												</li>
												<li>
													Visit DIRO to learn more about{" "}
													<a href="https://diro.io/use-cases/vendor-onboarding-kyb/" target="blank" rel="noopener">
														vendor onboarding.
													</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div className="accordion-item">
									<h2 className="accordion-header" id="flush-headingTwo">
										<button className={`accordion-button ${accordion.acc4 && "collapsed"}`} type="button" onClick={() => handleToggleBody("acc4")}>
											{accordion.acc4 ? (
												<img className="plus-minus" src={PlusIcon} alt="more" />
											) : (
												<img className="plus-minus" src={MinusIcon} alt="less" />
											)}
											How Fraud.net and DIRO create a new global standard?
										</button>
									</h2>
									<div id="flush-collapseTwo" className={`accordion-collapse${accordion.acc4 ? " collapse-box" : ""}`}>
										<div className="accordion-body">
											<ul>
												<li>Fraud.net and DIRO's solution eliminates the possibility of document fraud, email spoofing, and impersonation issues.</li>
												<li>
													Fraud.net and DIRO's ephemeral architecture of each session does not permit, or make possible, any storage of passwords or
													other login credentials. This makes the solution particularly applicable to bank account verification in enterprise
													onboarding to eliminate fraud.
												</li>
												<li>
													Fraud.net and DIRO's verification of bank statements provides verification of selected source data directly from the bank
													without the possibility of tampering.
												</li>
												<li>
													KYV (Know Your Vendor) is mostly a manual process with uploads of simple PDFs which is open to tampering, fake, and stolen
													data. This can lead to fraud and money laundering. Fraud.net and DIRO is solving this problem by creating a new global
													standard for document verification in vendor onboarding.
												</li>
												<li>
													DIRO participated in the UK's{" "}
													<a
														href="https://www.fca.org.uk/firms/innovation/regulatory-sandbox/accepted-firms"
														target="blank"
														rel="noopener noreferrer"
													>
														FCA (Financial Conduct Authority) sandbox for KYC
													</a>{" "}
													in 2019 (cohort 5) with its bank account verification and proof of address solution.
												</li>
												<li>
													In addition to the UK's FCA, DIRO is in conversation with a number of regulators including FATF, FinCEN, Malta Gaming
													Authority, Central Bank of India, Central Bank of Bahrain, Central Bank of Malaysia, etc. The reason for these conversations
													is to educate and help regulators to strengthen KYC, KYB, and AML requirements based on advancing technology.
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<p className="sticky_para">
								Learn more about DIRO's{" "}
								<a href="https://diro.io/products/bank-account-verification/" target="blank" rel="noopener noreferrer">
									bank account
								</a>{" "}
								and{" "}
								<a href="https://diro.io/products/utility-bill-verification/" target="blank" rel="noopener noreferrer">
									address verification
								</a>{" "}
								solutions.
							</p>
						</div>
					</div> */}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
