import React, { useState,useRef,useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { Accordion } from "react-bootstrap";
import '../App.css'

const   RightSideBar = () => {
  const [activeKey, setActiveKey] = useState(null);
  return (
<div
      className="d-flex justify-content-start align-self-start w-100 pt-4 pt-lg-0 pb-lg-0 pb-md-0 pb-5 mb-3 mb-lg-0 mb-md-0"
    >
      <div

        className={`overflow-scroll align-items-start me-md-5 ms-md-5 scrollbar-area me-lg-0 pe-lg-5 pe-5 ipad-air-class w-100 pt-lg-0 pt-md-0 pt-lg-0 pr-0 `}
        // style={{ marginRight: '0px !important' }}
        
      >
        <div className="right-line">
        <div className="scrollbar-text ms-md-0">
          <p className="fs-5" style={{ fontWeight: "500", padding: "0", fontFamily:"bwekBoldFont"}}>
            <span className="fs-5 overflow" style={{color:"black"}}> Frequently asked questions </span>
          </p>
          <Accordion
            activeKey={activeKey}
            onSelect={(key) => setActiveKey(key)}
            className="accord custom-accordion"
            style={{color:"#787878",fontFeatureSettings: "ss06"}}
          >
            <Accordion.Item eventKey="0" className="border-0">
              <Accordion.Header>
                <FontAwesomeIcon
                  icon={activeKey === "0" ? faMinus : faPlus}
                  className="text-dark-yellow me-2"
                />
                <span className="fs-6" style={{color:"black"}} > What information will be shared?</span>
              </Accordion.Header>
              <Accordion.Body className="pe-0">
                <ul>
                  <li style={{color:"#787878"}}>
                    Your bank statement will not be shared with BW Epic Kosan. Only
                    selected fields as highlighted inside each session will be
                    shared. All other data will be purged.
                  </li>
                  <li style={{color:"#787878"}}>
                    Your utility bill will only be shared with BW Epic Kosan with
                    your consent in each session.
                  </li>
                  <li style={{color:"#787878"}}>
                    Any sensitive information such as balance or transactional
                    data from your bank statement will never be shared with BW
                    Epic Kosan.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" className="border-0">
              <Accordion.Header>
                <FontAwesomeIcon
                  icon={activeKey === "1" ? faMinus : faPlus}
                  className="text-dark-yellow me-2"
                />
                <span className="fs-6" style={{color:"black"}}>
                  How does my password remain private?{" "}
                </span>
              </Accordion.Header>
              <Accordion.Body className="pe-0">
                <ul>
                  <li style={{color:"#787878"}}>
                    The entire verification session provides end-to-end
                    encryption and remains private. In other words, you will not
                    share your password or login credentials with DIRO or BW
                    Epic Kosan.
                  </li>
                  <li style={{color:"#787878"}}>
                    Most banks use one-time passwords (OTP) with multi-layer
                    security.
                  </li>
                  <li style={{color:"#787878"}}>
                    Most banks use two-factor authentication (2FA) or
                    multi-factor authentication (MFA). The second factor is
                    sometimes offline or on a separate device, such as a
                    smartphone for high security and to eliminate fraud.
                  </li>
                  <li style={{color:"#787878"}}>
                    Since you will not do a transaction, only verify bank
                    account ownership and proof of address, your transaction
                    password will never be asked for in the verification
                    session.
                  </li>
                  <li style={{color:"#787878"}}>Visit DIRO to learn more about user privacy.</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2" className="border-0">
              <Accordion.Header>
                <FontAwesomeIcon
                  icon={activeKey === "2" ? faMinus : faPlus}
                  className="text-dark-yellow me-2"
                />
                <span className="fs-6" style={{color:"black"}}>Why should I trust DIRO? </span>
              </Accordion.Header>
              <Accordion.Body className="pe-0 ">
                <p className="ps-4" style={{color:"#787878"}}>
                  DIRO is trusted by F500 and Tier 1 global banks in consumer
                  and enterprise verifications across 195 countries. Its
                  solution is used to verify bank account ownership, proof of
                  address, and other documents to eliminate fraud, email
                  spoofing, and impersonation issues.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3" className="border-0">
              <Accordion.Header>
                <FontAwesomeIcon
                  icon={activeKey === "3" ? faMinus : faPlus}
                  className="text-dark-yellow me-2"
                />
                <span className="fs-6" style={{color:"black"}}>
                  How DIRO creates a new global standard?
                </span>
              </Accordion.Header>
              <Accordion.Body className="pe-0">
                <ul>
                  <li style={{color:"#787878"}}>
                    DIRO's solution eliminates the possibility of document
                    fraud, email spoofing, and impersonation issues.
                  </li>
                  <li style={{color:"#787878"}}>
                    DIRO's ephemeral architecture of each session does not
                    permit or make possible any storage of passwords or other
                    login credentials. This makes the solution particularly
                    applicable to bank account verification and proof of address
                    in consumer and enterprise onboarding to eliminate fraud.
                  </li>
                  <li style={{color:"#787878"}}>
                    DIRO's verification of bank statements and utility bills
                    provides verification of selected source data directly from
                    the bank or utility company without the possibility of
                    tampering.
                  </li>
                  <li style={{color:"#787878"}}>
                    KYB (Know Your Business) is mostly a manual process with
                    uploads of simple PDFs which is open to tampering, fake, and
                    stolen data. This can lead to fraud and money laundering.
                    DIRO is solving this problem by creating a new global
                    standard for document verification in KYB.
                  </li>
                  <li style={{color:"#787878"}}>
                    DIRO participated in the UK's{" "}
                    <a
                      href="https://www.fca.org.uk/firms/innovation/regulatory-sandbox/accepted-firms"
                      target="blank"
                      rel="noopener"
                      className="anchor"     
                    >
                      FCA (Financial Conduct Authority) sandbox for KYC
                    </a>{" "}
                    in 2019 (cohort 5) with its bank account verification and
                    proof of address solution.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <div className="fs-6 pt-3">
            <span className="" style={{color:"black"}}>Learn more about DIRO's </span>
            <a
              href="https://diro.io/products/bank-account-verification/"
              target="_blank"
              className="anchor"
            >
              <span className="">bank </span>
            </a>{" "}
            <span className="" style={{color:"black"}}> account </span>
            <span className="" style={{color:"black"}}>and </span>
            <a
              href="https://diro.io/products/utility-bill-verification/"
              target="_blank"
              className="anchor"
            >
              <span className="" >address </span>
            </a>{" "}
            <span style={{color:"black"}}>verification solutions.</span>
          </div>
          <div className="py-2 pt-3 fs-6" style={{ marginBottom: "0rem" }}>
            <span className="pe-2 ">
              <a
                href="https://diro.io/term-condition/"
                className="anchor"
                target="_blank"
              >
                Terms of Use.
              </a>
            </span>{"  "}
            <span className="pe-2 ">
              <a
                href="https://diro.io/privacy-policy/"
                className="anchor"
                target="_blank"
              >
            Privacy Policy.
              </a>{"  "}
            </span>
            
            {/* <span>
              <a
                href="https://trust.diro.io/"
                className="anchor"
                target="_blank"
              >
                 Trust Center
              </a>
            </span> */}
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default RightSideBar;
