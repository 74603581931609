import React from "react";
import { Routes, Route } from "react-router-dom";
// import Home from "./Home";
import { useRef } from "react";
import Buttons from "./Buttons";
import Navbar from "./NavBar";
import Verification from "./Verification";
import Support from "./Support";
import Loader from "./Loader";
import HomePage from "./HomePage";

const Layout = (props) => {
  const iframeRef = useRef(null);
  return (
    <>
	  <Navbar iframeRef={iframeRef} />
      <Routes>
        <Route path="/" element={<HomePage />} />
        {/* <Route path="/buttons" element={<Buttons />} /> */}
        <Route path="/verification/:trackId" element={<Verification iframeRef={iframeRef}/>} />
        <Route path="*" element={<HomePage />} />
        <Route path="/support" element={<><HomePage className="supportmodal"/><Support /></>} />
        <Route path="/loader" element={<Loader />} />
      </Routes>
    </>
  );
};

export default Layout;
