import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import "../App.css";

const InputField = (props) => {
  const { setReference } = props;
  const [value, setValue] = useState("");
  const [showError, setshowerror]=useState(false);
  const [emailValidation, setEmailValidation] = useState(true);

  const handleClick = () => {
    if(value === ""){
      setshowerror(true)
      return;
    }
    if (
			//eslint-disable-next-line
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				value
			)
		){
    localStorage.setItem("reference", value);
    setReference(value);
    }
    else{
      setEmailValidation(false);
			setTimeout(() => {
				setEmailValidation(true);
			}, 5000);
    }
  };

  const handleInputChange = (e) => {
    const lowercaseValue = e.target.value.toLowerCase();
    
    setValue(lowercaseValue);
    setshowerror(false)  //hide error  while typing

  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleClick();
    }
  };
  // const adjustment = {
  //   display:"flex",
  //   height: "2rem",
  //   width: "15rem",
  // };
  return (
    <div className=" mt-lg-4 mt-3 mb-lg-0 mb-4" style={{ display:"block",
    height: "2rem",
    width: "15rem",}}>
      <InputGroup className="form">
        <Form.Control
          placeholder="Email"
          aria-label="Refernce ID"
          aria-describedby="referenceId"
          className="small-input border-2 border-end-0 p-2 fs-6 emailbox"
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          style={{border: "1px solid #FF4F00"}}
          type="email"
        />
        <div
          style={{ border: "2px solid #FF4F00"}}
          className="border-start-0 rounded-end-3 "
          s
        >
           <div style={{display:"block"}}>
          <InputGroup.Text
            style={{ padding: "8px 12px", transform: "translate(4%,0%)", border: "1px solid #FF4F00" ,backgroundColor: "#FF4F00"}}
            className="btn btn-primary rounded-3  btn-md text-white "
            id="referenceId"
            onClick={handleClick}
          >
            Go
            
          </InputGroup.Text>
          
          </div>
        </div>
       
      </InputGroup>
      <div style={{display:"inline-block"}}>
        {(showError || !emailValidation )&& (
        <p style={{ 
          color: "#FF4F00",
          marginLeft:"5px",
          marginTop:"5px",
          fontWeight: 400}}>Please enter a valid email</p>
      )}
      </div>
    </div>
  );
};
export default InputField;
