import React, { useState } from "react";
const Footer = () => {
  const [mobileWarning, setMobileWarning] = useState(true);
  const handleCloseWarning = () => {
    setMobileWarning(false);
  };
  return (
    <>
      {mobileWarning && (
        <div className="desktop-optimized text-nowrap ps-3 pe-2">
        <i className="close-mobile-warning mt-2" onClick={handleCloseWarning}></i>
        <p  className="mt-3 text-center">
          Please use a desktop for an optimized user experience.
        </p>
      </div>
      )}
    </>
  );
};
export default Footer;  