import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../context";

function NavScrollExample({ iframeRef }) {
  const location = useLocation();
  const { setMobileWarning } = useContext(Context);

  const handleLogoClick = () => {
    // console.log("handleLogoClick");
    // console.log(location.pathname);
    // ===== Post message to capture-process iframe to open the exit survey page =====
    if (location.pathname.includes("/verification")) {
      // console.log("condition passed");
      // console.log(iframeRef);
      iframeRef?.current?.children[0]?.contentWindow.postMessage(
        "Exit cbre session",
        "*"
      );
    }
  };
  const handleFocus = () => {
    // console.log("handleFocus");
    iframeRef?.current?.children[0].focus();
  };

  const handleClickOutside = (event) => {
    if (window.location.href.includes("/verification")) {
      event.preventDefault();
      handleFocus();
    }
  };

  document.addEventListener("mousedown", handleClickOutside);

  return (
    <Navbar expand="lg" className="nav-bg p-0 " variant="dark">
      <Container fluid className="nav-bg p-0" style={{backgroundColor:"#b9b9b97a"}}>
        {/* <Navbar.Brand
          // onClick={handleLogoClick}
          className="col-lg-4 text-start "
          style={{margin:"0rem 0rem -3rem 1rem ",backgroundColor:"grey"}}
        > */}
          {/* <img
            src={require("../assets/epic-gas.png")}
            style={{ width: "150px",height: "60px"}}
            alt="logo not loaded"
            className="ps-lg-1 img-fluid cursor-pointer"
          /> */}
           {/* <div style={{height:"3.5rem",width:"100%", font:"bwekBoldFont",fontSize:"1.5rem", fontWeight:"600", marginLeft: "1rem", marginTop: "1rem", color:" #000000"
}}>
          Supplier Verification
      </div> */}
          {/* <hr style={{boxShadow:"rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"}}/> */}
        {/* </Navbar.Brand> */}
        {/* <Navbar.Toggle aria-controls="navbarScroll" /> */}
        
        <Navbar.Collapse>
          <Nav style={{ maxHeight: "100px" }} className="ps-4">
            {/* <Nav.Link href="#" className="text-light opacity-75">
              HOME
            </Nav.Link>
            <Nav.Link href="#" className="text-light opacity-75">
              ABOUTUS
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>

        
      </Container> 
    </Navbar>
    
  );
}

export default NavScrollExample;
