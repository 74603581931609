const test = {

	utilityButton: "https://verification.diro.live/?buttonid=O.IN-v6EsGi-FFR1&trackid=",

	//BANK BUTTON LINK
	bankButton: "https://verification.diro.live/?buttonid=O.IN-v6EsGi-UO1G&trackid=",
	
	feedbackUrl: "https://api2.diro.live/cbre/api/sendsupportemail",
	frameSource: "https://session.diro.live/ https://verification.diro.live/",

	//bank button full doc
	bankButtonFullDoc: "https://verification.diro.live/?buttonid=O.IN-v6EsGi-Ha5C&trackid=",
	// bankButtonFullDoc: "https://diro.io/verification?buttonid=O.IN-ZGIvIV-nA08&trackid=",
	dirostatus: "https://stage2.diro.live/Zuul-1.0/organization-2.0/dirostatus",

	staticUtilityButtonId: "O.IN-v6EsGi-FFR1",
	staticBankOnlyButtonId: "O.IN-v6EsGi-UO1G",
	staticBankFullButtonId: "O.IN-v6EsGi-UO1G",

	
	// staticUtilityButtonId: "O.IN-ZGIvIV-Kdhm",
	// staticBankOnlyButtonId: "O.IN-ZGIvIV-Sy6C",
	// staticBankFullButtonId: "O.IN-ZGIvIV-nA08",

	sentryDSN: "https://4e8f158588514409b25905afa75f18b2@o1042621.ingest.sentry.io/4506834708987904",
	environment: "test",
};

const prod = {

	utilityButton: "https://diro.io/verification?buttonid=O.SG-5y1NbH-UKpq&trackid=",
	
	//BANK BUTTON LINK
	bankButton: "https://diro.io/verification?buttonid=O.SG-5y1NbH-doHO&trackid=",

	//bank button full doc
	bankButtonFullDoc: "https://diro.io/verification?buttonid=O.SG-5y1NbH-doHO&trackid=",

	dirostatus: "https://prod.dirolabs.com/Zuul-1.0/organization-2.0/dirostatus",
	feedbackUrl: "https://api.diro.io/cbre/api/sendsupportemail",
	frameSource: "https://session.diro.io/ https://diro.io/",
	// feedbackUrl: "https://api2.diro.live/cbre/api/sendsupportemail",
	sentryDSN: "https://c9c38797b1ed2d40f04547ab74675f8c@o305199.ingest.us.sentry.io/4506874500087808",

	staticUtilityButtonId: "O.SG-5y1NbH-UKpq",
	staticBankOnlyButtonId: "O.SG-5y1NbH-doHO",
	staticBankFullButtonId: "O.SG-5y1NbH-doHO",

	environment: "prod",
};
export { test   };
export { prod as env };
