import React, { useState, useContext, useEffect } from "react";
import Button from "react-bootstrap/Button";
import "../App.css";
import { Context } from "../context";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Hourglass } from "react-loader-spinner";
import { env as environment } from "../controllers/environment";
import { Tooltip } from "react-tooltip";

const Buttons = (props) => {
  const { reference } = props;
  const navigate = useNavigate();
  const {
    inputEmail,
    setInputEmail,
    setButtonSelection,
    buttonStatus,
    setButtonStatus,
    setExtraBankButton,
    setButtonIndex,
    setResubmit,
    setSupportPage,
  } = useContext(Context);
  // axios.defaults.headers.common["Authorization"] = 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJwcmFkeXVtbmFAZGlyby5pbyIsImFwaWtleSI6IjUwNmNhMjI3MzMzZjI0N2M4NzBmYTQ5OTE5NTgyMjQ4In0.ROTMhaKc7D_snWwO1wur7oAY73hyJfVKgLZgbUkCYrYHSfEBa0oMKo4RX3xL2zYiX1mXQEqD10HOPSjRVUhAIw;'
  const [emailValidation, setEmailValidation] = useState(true);
  const [showButton, setshowButton] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [spinner1, setSpinner1] = useState(false);

  const [utilityExecutionStatus, setUtilityExecutionStatus] = useState("");
  const [bankOnlyExecustionStatus, setBankOnlyExecustionStatus] = useState("");
  const [bankFullExecustionStatus, setBankFullExecustionStatus] = useState("");

  const bankVerification = () => {
    setButtonSelection("bank_verify");
    setResubmit(false);
    navigate(`/verification/${reference}`);
  };

  const addressVerification = () => {
    setButtonSelection("utility_verify");
    setResubmit(false);
    navigate(`/verification/${reference}`);
  };

  const getSupport =() => {
    setButtonSelection("utility_verify");
    setResubmit(false);
    navigate(`/support`);
  }

  const bankVerificationFullDoc = () => {
    setButtonSelection("bank_verify_full_doc");
    setResubmit(false);
    navigate(`/verification/${reference}`);
  };

  const adjustment = {
   
    fontWeight: 600,
    fontSize: "16px",
  };

  //utility

  useEffect(() => {
    const getUtilityStatus = async () => {
      // var json = {buttonid:'O.c6257a91-48c1-48a7-9f0b-e7724f25cabf-lRgz',trackid: localStorage.getItem('reference')};

      var json = {
        buttonid: environment.staticUtilityButtonId,
        trackid: localStorage.getItem("reference"),
      };
      try {
        const res = await axios.post(environment.dirostatus, json);

        const buttonStatus = res.data.message;

        if (buttonStatus === "JSON valid") {
          //done
          setUtilityExecutionStatus("completed");
        } else if (buttonStatus === "Done, in review." || sessionStorage.getItem("submitStatus")==='address') {
          setUtilityExecutionStatus("processing");
          setTimeout(()=>sessionStorage.clear(),4000);
        } else if (buttonStatus === "Invalid submission") {
          //done

          setUtilityExecutionStatus("rejected");
        } else if (buttonStatus === "fileobject is not present") {
          setUtilityExecutionStatus("notPresent");
        } else {
          setUtilityExecutionStatus("");
        }
      } catch {
        console.log("something went wrong in fetch data of utility button");
      }
    };
    getUtilityStatus();
  }, []);

  //bank only
  useEffect(() => {
    const getBankStatus = async () => {
      var json = {
        buttonid: environment.staticBankOnlyButtonId,
        trackid: localStorage.getItem("reference"),
      };
      try {
        const res = await axios.post(environment.dirostatus, json);
        const buttonStatus = res.data.message;

        if (buttonStatus === "JSON valid") {
          //done
          setBankOnlyExecustionStatus("completed");
        } else if (buttonStatus === "Done, in review."|| sessionStorage.getItem("submitStatus")==='bank') {
          setBankOnlyExecustionStatus("processing");
          setTimeout(()=>sessionStorage.clear(),4000);
        } else if (buttonStatus === "Invalid submission") {
          //done

          setBankOnlyExecustionStatus("rejected");
        } else if (buttonStatus === "fileobject is not present") {
          setBankOnlyExecustionStatus("notPresent");
        } else {
          setBankOnlyExecustionStatus("");
        }
      } catch {
        console.log("something went wrong in fetch data of utility button");
      }
    };
    getBankStatus();
  }, []);

  //bank full
  // useEffect(() => {
  //   const getBankFullStatus = async () => {
  //     var json = {
  //       buttonid: environment.staticBankFullButtonId,
  //       trackid: localStorage.getItem("reference"),
  //     };
  //     try {
  //       const res = await axios.post(environment.dirostatus, json);
  //       const buttonStatus = res.data.message;

  //       if (buttonStatus === "JSON valid") {
  //         //done
  //         setBankFullExecustionStatus("completed");
  //       } else if (buttonStatus === "Done, in review."|| sessionStorage.getItem("submitStatus")==='bankfull') {
  //         setBankFullExecustionStatus("processing");
  //         setTimeout(()=>sessionStorage.clear(),4000);
  //       } else if (buttonStatus === "Invalid submission") {
  //         //done

  //         setBankFullExecustionStatus("rejected");
  //       } else if (buttonStatus === "fileobject is not present") {
  //         setBankFullExecustionStatus("notPresent");
  //       } else {
  //         setBankFullExecustionStatus("");
  //       }
  //     } catch {
  //       console.log("something went wrong in fetch data of utility button");
  //     }
  //   };
  //   getBankFullStatus();
  // }, []);

  return (
    <>
      <div
        className="ms-lg-3 ms-md-0 ps-md-0 ms-4 ps-0  ps-lg-3 mt-3 mt-lg-2 d-flex flex-column align-items-start padding-fit button_container"
        style={{ height: "240px", width: "450px" }}
      >
        {/* <div>
          <p className="fs-5" style={{ fontWeight: "500" }}>
            Address Verification:
          </p>{" "}
        </div>  */}
        <div className="align-items-center mb-3 pe-lg-5">
          <Button
            style={adjustment}
            className={`adjustment rounded rounded-3 ms-lg-5 ${
              utilityExecutionStatus === "completed" ||
              utilityExecutionStatus === "processing"
                ? "btn-disabled-grey border-dark-grey"
                : "bg-dark-yellow border-dark-yellow"
            }`}
            onClick={() => addressVerification()}
            disabled={
              // utilityExecutionStatus === "completed" ||
              utilityExecutionStatus === "processing"
            }
          >
            
            
            {
              utilityExecutionStatus !== "completed" && 
              <span className="fs-6" style={{fontFamily:"bwekFont"}}>Address verification</span>
            }
            {
              utilityExecutionStatus === "completed" && 
              <div className="d-flex flex-column" style={{fontFamily:"bwekFont"}}>
              <span className="submit-another-2" style={{fontFamily:"bwekFont"}}>Utility bill</span>
              <br />
              <span className="submit-another" style={{fontFamily:"bwekFont"}}> Submit another</span>
              </div>
            }
          </Button>
            

          {utilityExecutionStatus === "completed" && (
            <span className="ms-3">
              <img
                src={process.env.PUBLIC_URL + "/assets/check-mark.png"}
                alt="Tick Icon"
                className="hover-cursor"
                data-tooltip-id="my-tooltip-completed"
                // data-tooltip-content=" The document is successfully submitted."
                data-tooltip-place="top"
              />
              <Tooltip id="my-tooltip-completed" html="The document is successfully <br/>submitted." />
            </span>
            
          )}

          {utilityExecutionStatus === "rejected" && (
            <span className="ms-3">
              <img
                src={process.env.PUBLIC_URL + "/assets/baddoc.png"}
                alt="Rejected Icon"
                className="hover-cursor"
                data-tooltip-id="my-tooltip-rejected"
                // data-tooltip-content="The document was rejected, please resubmit."
                data-tooltip-place="top"
                style={{ width: "26px", height: "26px" }}
              />
              <Tooltip id="my-tooltip-rejected" html="The document was rejected,<br/>please resubmit." />
            </span>
          )}
          

          {utilityExecutionStatus === "processing" && (
            <div style={{ position: "relative", display: "inline-block" }}>
            <span
              className="ms-3 hover-cursor"
              data-tooltip-id="my-tooltip-utility"
              // data-tooltip-content="The document is submitted and is under processing."
              data-tooltip-place="top"
              style={{ width: "26px", height: "26px"}}
              
            >
              
              <Hourglass
                visible={true}
                width="23"
                height="23"
                wrapperStyle={{}}
                ariaLabel="hourglass-loading"
                colors={["#6f5f38", "#6f5f38"]}
                wrapperClass=""
              />
              <Tooltip id="my-tooltip-utility" html="The document is submitted  and </br> is  under processing." /> 
            </span>
            </div>
          )}
          
        </div>
        

        {/* bank only button */}
        <div className="align-items-center mt-4 mb-3">
          <Button
            style={{ ...adjustment }}
            className={`adjustment rounded rounded-3 ms-lg-5 ${
              bankOnlyExecustionStatus === "completed" ||
              bankOnlyExecustionStatus === "processing"
                ? "btn-disabled-grey border-dark-grey"
                : "bg-dark-yellow border-dark-yellow"
            }`}
            onClick={() => bankVerification()}
            disabled={
              // bankOnlyExecustionStatus === "completed" ||
              bankOnlyExecustionStatus === "processing"
            }
          >
            {/* <span className="fs-6 ">Bank Verification (address only)</span> */}

              {
              bankOnlyExecustionStatus !== "completed" && 
              <span className="fs-6" style={{fontFamily:"bwekFont"}}>Bank account verification</span>
            }
            {
              bankOnlyExecustionStatus === "completed" && 
              <div className="d-flex flex-column">
              <span className="submit-another-2"> Bank account verification</span>
              <br />
              <span className="submit-another"> Submit another</span>
              </div>
            }
          </Button>

          {bankOnlyExecustionStatus === "completed" && (
            <span className="ms-3">
              <img
                src={process.env.PUBLIC_URL + "/assets/check-mark.png"}
                alt="Tick Icon"
                className="hover-cursor"
                data-tooltip-id="my-tooltip-bank-completed"
                // data-tooltip-content="The document is successfully submitted."
                data-tooltip-place="top"
              />
              <Tooltip id="my-tooltip-bank-completed" html="The document is successfully <br/> submitted." />
            </span>
          )}
          
          {bankOnlyExecustionStatus === "rejected" && (
            <span className="ms-3 hover-cursor">
              <img
                src={process.env.PUBLIC_URL + "/assets/baddoc.png"}
                alt="Rejected Icon"
                style={{ width: "26px", height: "26px", marginTop: "15px" }}
                data-tooltip-id="my-tooltip-bank-rejected"
                // data-tooltip-content="The document was rejected, please resubmit."
                data-tooltip-place="top"
              />
              <Tooltip id="my-tooltip-bank-rejected" html="The document was rejected,<br/>please resubmit." />

            </span>
          )}

          {bankOnlyExecustionStatus === "processing" && (
            <span
              className="ms-3 hover-cursor"
              data-tooltip-id="my-tooltip-bank-processing"
              // data-tooltip-content="The document is submitted and is under processing. "
              data-tooltip-place="top"
              style={{width:"20px"}}
            >
              <Hourglass
                visible={true}
                width="23"
                height="23"
                wrapperStyle={{}}
                ariaLabel="hourglass-loading"
                colors={["#6f5f38", "#6f5f38"]}
                wrapperClass=""
              />
              <Tooltip id="my-tooltip-bank-processing" html="The document is  submitted  and <br/> is under processing." />

            </span>
          )}
        </div>

        {/* bank full statement */}

        {/*get support button */}

        <div className="align-items-center mt-4 " >
          <Button
            style={{ color:"#FF4F00", border:"2px solid #FF4F00"}}
            className={`adjustment rounded rounded-3 ms-lg-5 ${
            
            "bg-transparent border-dark-yellow"
            }`}
            onClick={() =>getSupport()}
          >
{/* 
              {
              bankOnlyExecustionStatus !== "completed" &&  */}
              <span className="fs-6" style={{fontWeight:"700"}}>Get support</span>
            {/* } */}
            {/* {
              <div className="d-flex flex-column mb-3">
              <span className="submit-another-2">Get support</span>
              <br />
              <span className="submit-another"> Submit another</span>
              </div>
            } */}
          </Button>

          {/* {bankOnlyExecustionStatus === "completed" && (
            <span className="ms-3">
              <img
                src={process.env.PUBLIC_URL + "/assets/check-mark.png"}
                alt="Tick Icon"
                className="hover-cursor"
                data-tooltip-id="my-tooltip-bank-completed"
                data-tooltip-content=" Submission completed "
                data-tooltip-place="top"
              />
              <Tooltip id="my-tooltip-bank-completed" />
            </span>
          )}

          {bankOnlyExecustionStatus === "rejected" && (
            <span className="ms-3 hover-cursor">
              <img
                src={process.env.PUBLIC_URL + "/assets/baddoc.png"}
                alt="Rejected Icon"
                style={{ width: "26px", height: "26px", marginTop: "15px" }}
                data-tooltip-id="my-tooltip-bank-rejected"
                data-tooltip-content="Invalid submission "
                data-tooltip-place="top"
              />
              <Tooltip id="my-tooltip-bank-rejected" />

            </span>
          )} */}

          {/* {bankOnlyExecustionStatus === "processing" && (
            <span
              className="ms-3 hover-cursor"
              data-tooltip-id="my-tooltip-bank-processing"
              data-tooltip-content="Processing "
              data-tooltip-place="top"
            >
              <Hourglass
                visible={true}
                width="23"
                height="23"
                wrapperStyle={{}}
                ariaLabel="hourglass-loading"
                colors={["#6f5f38", "#6f5f38"]}
                wrapperClass=""
              />
              <Tooltip id="my-tooltip-bank-processing" />

            </span>
          )} */}
        </div>

        {/* <div>
          <p className="fs-5 mt-4" style={{ fontWeight: "500" }}>
            Bank Verification:
          </p>
          <Button
            style={adjustment}
            className={`adjustment rounded rounded-3 ${
              bankFullExecustionStatus === "completed" ||
              bankFullExecustionStatus === "processing"
                ? "btn-disabled-grey border-dark-grey"
                : "bg-dark-yellow border-dark-yellow"
            }`}
            onClick={() => bankVerificationFullDoc()}
            disabled={
              // bankFullExecustionStatus === "completed" ||
              bankFullExecustionStatus === "processing"
            }
          >
            {/* <span className="fs-6">Bank statement (full document) </span> */}

              {/* {
              bankFullExecustionStatus !== "completed" && 
              <span className="fs-6">Bank statement (full document)</span>
            }
            {
              bankFullExecustionStatus === "completed" && 
              <div className="d-flex flex-column">
              <span className="submit-another-2"> Bank statement (full document) </span>
              <br />
              <span className="submit-another"> Submit another</span>
              </div>
            }
          </Button> */}

          {/* {bankFullExecustionStatus === "completed" && (
            <span className="ms-3">
              <img
                src={process.env.PUBLIC_URL + "/assets/check-mark.png"}
                alt="Tick Icon"
                className="hover-cursor"
                data-tooltip-id="my-tooltip-full-completed"
                data-tooltip-content=" Submission completed "
                data-tooltip-place="top"
                />
            <Tooltip id="my-tooltip-full-completed" />
            </span>
          )}

          {bankFullExecustionStatus === "rejected" && (
            <span className="ms-3">
              <img
                src={process.env.PUBLIC_URL + "/assets/baddoc.png"}
                alt="Rejected Icon"
                style={{ width: "26px", height: "26px"}}
                className="hover-cursor"
                data-tooltip-id="my-tooltip-full-rejected"
                data-tooltip-content="Invalid submission "
                data-tooltip-place="top"
              />
            <Tooltip id="my-tooltip-full-rejected" />
            </span>
          )}

          {bankFullExecustionStatus === "processing" && (
            <span
              className="ms-3 hover-cursor"
              data-tooltip-id="my-tooltip-full-processing"
              data-tooltip-content="Processing "
              data-tooltip-place="top"
            >
              <Hourglass
                visible={true}
                width="23"
                height="23"
                wrapperStyle={{}}
                ariaLabel="hourglass-loading"
                colors={["#6f5f38", "#6f5f38"]}
                wrapperClass=""
              />
            <Tooltip id="my-tooltip-full-processing" />
            </span>
          )}
        </div>  */}

        {/* <div>
        <Button  style={adjustment} className="m-auto border-dark-yellow rounded rounded-3 bg-light text-dark-yellow  border-2"
        onClick={()=>handleGetSupport()}>
          <span className="fs-6">Get Support</span>
        </Button>
      </div> */}

        <div className=" powered-by-diro" >
          <p className=" d-flex justify-content-end align-items-center rounded rounded-3 w-100 m-0 pd-lg-4 ms-lg-5">
            <span style={{ fontSize: "11px", fontWeight: 500,color:"black"}}>
              Powered by&nbsp;
            </span>
            <img
              src={process.env.PUBLIC_URL + "/assets/powered-by-diro.png"}
              alt="Error while loading image"
              style={{ width: "28px" }}
            />
          </p>
        </div>
      </div>
    </>
  );
};

export default Buttons;
